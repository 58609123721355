<template>
    <div class="relative w-full">
        <div class="flex justify-between items-center bg-tp-black p-3 rounded-xl" :class="{
            'border-2 border-red-500': isShouldPlay,
            'border-2 border-tp-yellow': !isShouldPlay,
            'yellow-shadow': index === 1 && !isShouldPlay
        }">
            <div class="flex items-center gap-2 text-tp-yellow">
                <p class="font-bold" :class="isShouldPlay ? 'opacity-0' : 'opacity-100'">{{ index }}</p>
                <img :src="rankingSong.image" alt="" class="w-12 h-12 rounded-full border-2 border-tp-yellow">
                <div class="flex flex-col justify-center font-semibold h-12">
                    <p class="text-md leading-none">{{ cleanedText }}</p>
                    <p v-if ="!isShouldPlay && !hideBalance" class="text-xs">{{rankingSong.revenue}} €</p>
                </div>
            </div>
            <div v-if ="!isShouldPlay" class="flex items-center justify-center px-4 rounded-full bg-tp-yellow">
                <p class="font-semibold text-sm py-1 text-tp-black">{{ rankingSong.votes }}</p>
            </div>
        </div>
        <button v-if ="!isGeneralVoting" class="absolute -right-2 -top-2 w-6 h-6 rounded-full bg-tp-yellow text-tp-black" @click="handleDeleteSong">-</button>
    </div>
</template>

<script>
import { computed } from 'vue';

export default {
    name: 'DJRankingSongCard',
    props: {
        rankingSong: Object,
        index: Number,
        isGeneralVoting: Boolean,
        rankingFinished: Boolean,
        isShouldPlay:Boolean,
        hideBalance: Boolean,
    },
    setup(props, { emit }) {
        const handleDeleteSong = () => {
            emit('deleteSong');
        }

        const cleanedText = computed(() => {
            const concatenatedText = `${props.rankingSong.songName} - ${props.rankingSong.artist}`; 

            return concatenatedText.length > 35 ? `${concatenatedText.slice(0, 35)}...` : concatenatedText;
        });
        return {
            handleDeleteSong,
            cleanedText
        }
    }
}
</script>

<style scoped>
@keyframes pulse-shadow {
  0% {
    box-shadow: 0 0 5px rgba(249, 249, 113, 0.5);
  }
  50% {
    box-shadow: 0 0 20px rgba(249, 249, 113, 1);
  }
  100% {
    box-shadow: 0 0 5px rgba(249, 249, 113, 0.5);
  }
}

.yellow-shadow {
  animation: pulse-shadow 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}
</style>