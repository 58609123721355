import createApiService from './apiService';

const apiService = createApiService();

async function sendLoungeEntrance(loungeId) {
  try {
      const url = `/stats/enterLounge`;
      
      let data = {
        loungeId: loungeId,
      }

      const method = 'post';
      
      const response = await apiService.request({
        method,
        url,
        data: data,
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      return response.data;
    } catch (error) {
      console.error('error during stats sent', error);
      throw error;
    }

}

export { sendLoungeEntrance };