<template>
  <div class="min-h-screen flex flex-col">
    <div class="relative w-full flex flex-col gap-4 bg-tp-black p-3 px-4 flex-1">
      <div class="relative flex justify-center py-2 items-center">
        <p class="text-tp-yellow text-3xl xs:text-4xl font-bold pt-2">TuParty</p>
      </div>
      <div class="flex flex-col justify-center items-center gap-4">
        <RankingSong 
          v-for="(rankingSong, index) in rankingSongs" 
          :key="index + 1" 
          :position="index + 1" 
          :image="rankingSong.image" 
          :song="rankingSong.songName" 
          :artist="rankingSong.artist"
          :votes="rankingSong.votes" 
          :songNotSelectedYet = "true"
          @addSong="handleAddSong" 
          @onBoostClick="handleBoost(rankingSong.songId)"
        />
      </div>
      <div class="add-song-button">
        <AddSongButton @click="handleAddSong" />
      </div>
      <ConfirmFreeVoteModal
        v-if="confirmModalVisible" 
        @onCloseModal="handleCloseConfirmModal" 
        :class="[{'transition-opacity duration-300 opacity-100 z-30': confirmModalVisible}, {'opacity-0': !confirmModalVisible}]"
      />
      <ConfirmFreeVoteModal
        v-if="confirmPromoCodeModal" 
        @onCloseModal="handleCloseConfirmModal" 
        :promocode="true"
        :class="[{'transition-opacity duration-300 opacity-100 z-30': confirmPromoCodeModal}, {'opacity-0': !confirmPromoCodeModal}]"
      />
      <Transition name="modal-fade">
        <AddVotesModal 
          v-if="boostModalVisible"
          :votePlans="votePlans" 
          :loungeDetails="loungeDetails"
          @submitVotePlan="handlePayment" 
          @onCloseModal="handleCloseBoostModal" 
          @onClickPromocode="handlePromocode"
          @onClickFreeVote="handleFreeVote"
          :class="[{'transition-opacity duration-300 opacity-100 z-30': boostModalVisible}, {'opacity-0': !boostModalVisible}]"
        />
      </Transition>
      <Transition name="modal-fade">
        <GenericInfoModal 
          v-if="promocodeFailedModal"
          :title="titlePromocodeFail"
          :message="messagePromocodeFail"
          :buttonText="'Entendido'"
          @onCloseModal="handleClosePromocodeFailedModal" 
        />
      </Transition>
      <Transition name="modal-fade">
        <SearchSongModal v-if="showSearchSongModal" @onCloseSearchSongModal="closeSearchSongModal" @onSearchSong="handleSearchSong" />
      </Transition>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, watch, watchEffect} from 'vue';
import RankingSong from '@/components/Ranking/RankingSong.vue';
import AddSongButton from '@/components/Ranking/AddSongButton.vue';
import AddVotesModal from '@/components/Common/AddVotesModal.vue';
import ConfirmFreeVoteModal from'@/components/Ranking/ConfirmFreeVoteModal.vue';
import GenericInfoModal from'../components/Common/GenericInfoModal.vue';
import SearchSongModal from '@/components/Common/SearchSongModal.vue';

import lens from '@/assets/images/lupa.png';
import logo from '@/assets/images/inverted-logo.png';
import trophy1 from '@/assets/images/trophy-1-outlined.png';
import trophy2 from '@/assets/images/trophy-2-outlined.png';
import trophy3 from '@/assets/images/trophy-3-outlined.png';
import user from '@/assets/images/user-icon.png';
import defaultSongImage from '@/assets/images/question-mark.png';
import votingService from '../service/votingService';
import { useRoute, useRouter } from 'vue-router';
import { useVotePlans } from '@/composables/votePlans.js';

export default {
  name: 'Ranking',
  components: {
    RankingSong,
    AddSongButton,
    ConfirmFreeVoteModal,
    AddVotesModal,
    GenericInfoModal,
    SearchSongModal
  },
  props: {
    loungeDetails: Object
  },
  emits: ['addSong', 'payment', 'onBoostClick', 'onClickBoost'],
  setup(props) {
    const showSearchSongModal = ref(false);
    const confirmModalVisible = ref(false);
    const boostModalVisible = ref(false);
    const songSelected = ref(null);
    const detailsLoaded = ref(false);
    const details = ref(null);
    const rankingSongs = ref([]);
    const route = useRoute();
    const loungeId = route.params.id;
    const loungeVotingService = votingService(loungeId);
    const router = useRouter();
    const confirmPromoCodeModal = ref (false);
    const promocodeFailedModal = ref(false);
    const messagePromocodeFail = ref(null);
    const titlePromocodeFail = ref(null);
    const promocode = ref('');
    const alreadyOpened = ref(false);
    const readyToOpenSearchModal = ref(false);

    const noModalOpen = () => {
      return (
        !confirmModalVisible.value &&
        !boostModalVisible.value &&
        !confirmPromoCodeModal.value &&
        !promocodeFailedModal.value &&
        !showSearchSongModal.value
      );
    };

  
    const updateRanking = async (songsData) => {
      rankingSongs.value = songsData.map(song => {
        return {
          ...song.songInfo,
          votes: song.votos
        };
      })
    };

    const handleSearchSong = (searchSong) => {
      showSearchSongModal.value = false;
      searchSong.value != null ? searchSong.value : searchSong.value = "";
      router.push(`/lounge/${loungeId}/add-song/${searchSong.value}`);
    }

    onMounted(() => {
      const previousRoute = route.meta?.previousRoute;
      
      if (previousRoute && previousRoute.includes('/add-song')) {
        showSearchSongModal.value = true;
        alreadyOpened.value=true;
      }

      if (props.loungeDetails && props.loungeDetails.public && props.loungeDetails.public.ranking) {
        const songsData = Object.values(props.loungeDetails.public.ranking.songs);
        updateRanking(songsData);
        detailsLoaded.value=true
      }
      setTimeout(() => {
        if (!alreadyOpened.value) {
          readyToOpenSearchModal.value = true;
        }
      }, 10000);

    });

    watchEffect(() => {
      if (
        readyToOpenSearchModal.value &&
        noModalOpen() &&
        !alreadyOpened.value
      ) {
        showSearchSongModal.value = true;
        alreadyOpened.value = true;
        readyToOpenSearchModal.value = false;
      }
    });


    watch(() => props.loungeDetails, (newDetails) => {
      if (newDetails && newDetails.public && newDetails.public.ranking) {
        details.value = newDetails;
        const songsData = Object.values(details.value.public.ranking.songs);

        if (songsData.length > 0) {
          updateRanking(songsData);
        }
      }
    }, { deep: true });

    const closeSearchSongModal = () => {
      showSearchSongModal.value = false;
    };

    const handleCloseConfirmModal = () => {
      confirmPromoCodeModal.value = false;
      confirmModalVisible.value = false;
    };

    const handleConfirmFreeVote = (isPromoCode) => {
      if (isPromoCode === true){
        confirmPromoCodeModal.value = true;
      }
      else{
        confirmModalVisible.value = true;
      }
    };

    const handleFreeVote = () => {
      try {
        boostModalVisible.value=false;
        loungeVotingService.rankingFreeBoost(songSelected.value);
        handleConfirmFreeVote();
      } catch (error) {
        console.log(error);
      }
    };

    const handlePromocodeRedirection = (promocode) => {
      router.push(`/lounge/${loungeId}/add-song/promo/${promocode}`);
    }

    const handlePromocode = async (promocode) => {
      try {
        console.log('song selected: ', songSelected.value);
        const response = await loungeVotingService.applyPromocode(songSelected.value, promocode);
        if (response.success === true) {
          boostModalVisible.value=false;
          handleConfirmFreeVote(true);
        } else if (response.error === "NO_EXIST") {
          titlePromocodeFail.value="No existe"
          messagePromocodeFail.value="El código promocional que has introducido no existe.  Revisa si has puesto alguna letra/número mal."
          promocodeFailedModal.value=true;
        } else if (response.error === "USED_USER") {
          titlePromocodeFail.value="Ya utilizado"
          messagePromocodeFail.value="El código promocional que has introducido ya lo has canjeado antes.  No puedes usarlo más de una vez :("
          promocodeFailedModal.value=true;
        } else if (response.error === "USED") {
          titlePromocodeFail.value="Ya utilizado"
          messagePromocodeFail.value="El código promocional que has introducido ya ha sido canjeado por alguien antes que tú, lo sentimos :("
          promocodeFailedModal.value=true;
        } else {
          titlePromocodeFail.value="Error"
          messagePromocodeFail.value="No sabemos qué ha podido pasar, pero algo no ha ido bien, prueba otra vez dentro de un rato."
          promocodeFailedModal.value=true;
        }
      } catch (error) {
        console.log('Error al aplicar el código promocional:', error);
        titlePromocodeFail.value="Error"
        messagePromocodeFail.value="No sabemos qué ha podido pasar, pero algo no ha ido bien, prueba otra vez dentro de un rato."
        promocodeFailedModal.value=true;
      }      
    };

    const handleBoost = (songId) => {
      songSelected.value = songId;
      boostModalVisible.value = true;
    };

    const handleCloseBoostModal = () => {
      boostModalVisible.value = false;
    };

    const handleClosePromocodeFailedModal = () => {
      promocodeFailedModal.value = false;
    };

    const handleAddSong = () => {
      router.push(`/lounge/${loungeId}/add-song`)
    };

    const handlePayment = (votePlanPrice) => {
      router.push(`/lounge/${loungeId}/song/${songSelected.value}/pay/${votePlanPrice}/ranking`);
    };

    const { votePlans } = useVotePlans();

    return {
      lens,
      logo,
      trophy1,
      trophy2,
      trophy3,
      user,
      defaultSongImage,
      rankingSongs,
      closeSearchSongModal,
      handleCloseConfirmModal,
      showSearchSongModal,
      confirmModalVisible,
      handleFreeVote,
      handleBoost,
      boostModalVisible,
      handleCloseBoostModal,
      handleAddSong,
      handlePayment,
      songSelected,
      details,
      detailsLoaded,
      votePlans,
      handlePromocodeRedirection,
      handlePromocode,
      confirmPromoCodeModal,
      messagePromocodeFail,
      titlePromocodeFail,
      promocodeFailedModal,
      handleClosePromocodeFailedModal,
      handleSearchSong,
      promocode,
    };
  }
};
</script>
<style scoped>
.add-song-button {
  position: fixed;
  bottom: calc(env(safe-area-inset-bottom) + 16px); /* Ajuste para dispositivos con notch */
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
}
</style>
