import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import "@/assets/css/main.css";
import Button from '@/components/Common/Button.vue';

const app = createApp(App);

// Verificar entorno de producción según VUE_APP_FRONT_BASE_URL
if (process.env.VUE_APP_FRONT_BASE_URL === "https://tuparty.club") {
  const script = document.createElement('script');
  script.async = true;
  script.src = 'https://www.googletagmanager.com/gtag/js?id=G-ENT9CX3C9C';
  document.head.appendChild(script);

  script.onload = () => {
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    window.gtag = gtag;

    gtag('js', new Date());
    gtag('config', 'G-ENT9CX3C9C'); 
  };

  router.afterEach((to) => {
    if (window.gtag) {
      window.gtag('config', 'G-ENT9CX3C9C', {
        page_path: to.fullPath,
      });
    }
  });
}

app.component('Button', Button).use(store).use(router).mount('#app');