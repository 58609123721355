<template>
    <div class="modal-overlay" @click.self="handleCloseModal">
      <div class="relative w-full px-6">
        <div class="absolute top-7 right-14 z-10 p-1 bg-tp-yellow rounded-full flex items-center justify-center cursor-pointer" @click="handleCloseModal">
          <img class="w-3 h-3" :src="close" alt="close-icon">
        </div>
        <div class="flex flex-col gap-8 justify-center border-4 border-tp-yellow bg-tp-black rounded-3xl pt-4 pb-6">
          <p class="text-tp-yellow font-bold text-3xl px-6 border-b border-tp-yellow pb-3">Personalizar</p>
          <p class="text-tp-yellow font-semibold px-6">
            ¿Quieres elegir 3 canciones manualmente o usar las 3 canciones más votadas?
          </p>
          <div class="flex gap-6 px-6">
            <button class="flex-1 whitespace-nowrap bg-tp-yellow text-tp-black font-bold px-3 py-2 rounded-xl" @click="emitChooseManual">
              Elegir 3
            </button>
            <button class="flex-1 whitespace-nowrap bg-tp-yellow text-tp-black font-bold px-3 py-2 rounded-xl" @click="emitChooseTop">
              Más votadas
            </button>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import close from '@/assets/images/black-close-icon.png';
  
  export default {
    name: 'CustomizationModal',
    setup(_, { emit }) {
      const handleCloseModal = () => {
        emit('onCloseModal');
      };
  
      const emitChooseManual = () => {
        emit('chooseManual');
      };
  
      const emitChooseTop = () => {
        emit('chooseTop');
      };
  
      return {
        close,
        handleCloseModal,
        emitChooseManual,
        emitChooseTop,
      };
    }
  };
  </script>
  