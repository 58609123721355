<template>
  <div class="modal-overlay" @click.self="handleCloseModal">
    <div class="relative w-full px-6">
      <div class="absolute top-7 right-14 z-10 p-1 bg-tp-yellow rounded-full flex items-center justify-center cursor-pointer"  @click="handleCloseModal">
        <img class="w-3 h-3" :src="close" alt="close-icon">
      </div>
      <div class="flex flex-col gap-8 justify-center border-4 border-tp-yellow bg-tp-black rounded-3xl pt-4 pb-6">
        <p class="text-tp-yellow font-bold text-3xl px-6 border-b border-tp-yellow pb-3">Añade votos</p>
        <div class="flex flex-col gap-4 px-6">
          <div class="flex items-center gap-1 animate-bounce">
            <img class="w-5 h-5" :src="info" alt="">
            <p class="font-bold text-md">Solo se cobra si ganas.</p>
          </div>
          <div v-if="isPromocodeActive" class="flex gap-2 p-2 bg-tp-black border-2 border-tp-yellow rounded-xl text-sm w-full">
            <input 
                type="text" 
                class=" bg-tp-black text-tp-yellow border-none outline-none pl-2 py-1 w-full" 
                placeholder="Código promocional"
                v-model="promocode"               
            />
            <Button 
                class="bg-tp-yellow text-tp-black px-4 py-2 rounded-md font-bold"
                @click="handlePromocode(promocode)"
                :disabled="!isPromocodeValid" 
            >
                Aplicar
            </Button>
          </div>
          <div class="flex flex-col gap-4">
            <VotePricing v-for="(votePlan, index) in votePlans" :isLoading="isLoading === index" :key="index" :plan="votePlan.plan" :price="votePlan.price" :votes="votePlan.votes" @click="handleSubmitVotePlan(votePlan, index)"/>
            <!--<div class="slider-container" style="margin-bottom: 20px;">
              <AmountSlider :min="1" :max="limitSpending" :step="1" @update:value="handleSliderUpdate" @redirect="handleRedirect" />
            </div>  -->        
          </div>
          <!-- Bloque de votos gratis -->
          <div class="flex flex-col gap-2 items-center px-2 text-center">
            <p v-if="delayedFree" class="text-sm text-tp-yellow font-semibold">
              Investiga un poco la aplicación antes de desbloquear tus votos gratis.
            </p>
            <p v-else class="text-sm text-tp-yellow font-semibold">
              Te queda<span v-if="availableFreeSongs !== 1">n</span> 
              <span class="text-2xl font-extrabold mx-1">{{ availableFreeSongs }}</span> 
              voto<span v-if="availableFreeSongs !== 1">s</span> gratis.
            </p>
            <button
              class="bg-tp-yellow text-tp-black font-bold px-4 py-2 rounded-md disabled:opacity-50"
              :disabled="availableFreeSongs <= 0 || delayedFree"
              @click="handleFreeVote"
            >
              GRATIS
            </button>
          </div>
        </div>      
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watch } from 'vue';
import close from '@/assets/images/black-close-icon.png';
import info from '@/assets/images/info-icon.png';
import VotePricing from './VotePricing.vue';
// import AmountSlider from './AmountSlider.vue';

export default {
  name: 'AddVotesModal',
  props: {
    isAddSong: Boolean,
    votePlans: Object,
    isLoading: Boolean,
    loungeDetails: Object,
  },
  emits: ['onCloseModal', 'submitVotePlan', 'onClickPromocode', 'onClickFreeVote'],
  components: {
    VotePricing,
    // AmountSlider
  },
  setup(props, { emit }) {

    const promocode = ref('');
    const isPromocodeValid = ref(false);
    const isPromocodeActive = ref(props.loungeDetails.public.promos_activas);
    const limitSpending = ref(props.loungeDetails.public.limit_spending);
    const availableFreeSongs = ref(props.loungeDetails.private.availableFreeBoosts)
    const delayedFree = ref(props.loungeDetails.private.delayedFreeBoost)

    const handleCloseModal = () => {
      emit('onCloseModal');
    };

    const handleSubmitVotePlan = (votePlan) => {
      emit('submitVotePlan', votePlan.price);
    };

    const handleRedirect = (value) => {
      emit('submitVotePlan', value);
    };

    const handlePromocode = (promocode) => {
      emit('onClickPromocode', promocode);
    };

    const handleFreeVote = () => {
      emit('onClickFreeVote');
    };

    watch(promocode, (newVal) => {
      isPromocodeValid.value = newVal.trim().length > 0;
    });

    watch(() => props.loungeDetails, (newVal) => {
      availableFreeSongs.value = newVal?.private?.availableFreeBoosts;
      delayedFree.value = newVal?.private?.delayedFreeBoost;
    }, { deep: true });


    return {
      close,
      info,
      handleCloseModal,
      handleSubmitVotePlan,
      handleRedirect,
      isPromocodeActive,
      isPromocodeValid,
      promocode,
      handlePromocode,
      limitSpending,
      delayedFree,
      availableFreeSongs,
      handleFreeVote
    }
  }
};

</script>
