import { ref } from 'vue';

export function useVotePlans() {
  const votePlans = ref([
    {
      plan: 'TuParty Basic',
      price: 1.49,
      votes: 5
    },
    {
      plan: 'TuParty Advanced',
      price: 4.99,
      votes: 24
    }
  ]);
  //también cambiar la función votes de stripepayment si se modifican las cantidades de votos
  return {
    votePlans
  };
}
