<template>
    <div class="flex flex-col items-center justify-center h-screen bg-tp-black">
      <p class="text-tp-yellow text-3xl font-semibold text-center px-4">
        ¡Te ha caducado la sesión por seguridad!
      </p>
      <p class="text-tp-yellow text-lg font-medium text-center mt-4 px-4">
        Por favor, vuelve a escanear el QR/darle al link que te hemos facilitado
      </p>
      <button class="border-2 border-tp-yellow bg-tp-black text-tp-yellow font-bold text-lg py-2 rounded-full px-6 mt-4" @click="redirectLogin">Tengo usuario</button>
    </div>
  </template>
  
  <script>
  import { useRouter } from 'vue-router';


  export default {
    name: 'SessionExpired',

    setup() {
      const router = useRouter();

      const redirectLogin = () => {
        router.push("/dj/login");
      }


      return {
        redirectLogin
      }
    }
  };
  </script>
  
  <style scoped>
  </style>
  