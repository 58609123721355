<template>
    <div class="h-screen w-full flex items-center justify-center">
      <p>Entrando a TuParty DJ...</p>
    </div>
  </template>
  
  <script>
  import { onMounted } from 'vue';
  import { useRouter, useRoute } from 'vue-router';
  import { accessByQR } from '../../service/djService'

 
  
  export default {
    name: 'DJQRAccess',
    setup() {
      const router = useRouter();
      const route = useRoute();
  
      onMounted(async () => {
        try {
          const cookie = route.params.cookie;
          
  
          if (!cookie) {
            console.error('Cookie no encontrada en la URL');
            router.push('/dj/login'); 
            return;
          }
  
          const response = await accessByQR(cookie);
  
          if (!response || response.result=== false) {
            console.error('Error al iniciar sesión con QR:', response?.error);
            router.push('/dj/login'); 
            return;
          }else{
            router.push('/dj/home/qrAccess')
          }
  
        } catch (error) {
          console.error('Error al manejar DJQRAccess:', error);
          router.push('/dj/login');
        }
      });
  
      return {
      };
    },
  };
  </script>
  