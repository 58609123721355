<template> 
    <div v-if="!isLoading" class="flex flex-col items-center gap-10 pb-10">
        <NavBar />
        <div class="flex flex-col gap-8 w-full">
            <div class="flex flex-col items-center gap-4 px-4">
                <DJHomeOption v-if="isFromLogin" @click="handleRedirect('my-profile')" text="MI PERFIL" :balance="balance"/>
                <DJHomeOption v-if="!existsActiveSession" @click="handleRedirect('create-session')" text="CREAR SESIÓN" :disabled="cantCreateSession"/>
                <DJActiveSessionOption v-else :sessionsInfo="sessionsInfo" @click="handleRedirect('create-session')" text="SESIÓN ACTIVA" />
                <div class="flex items-center gap-2 justify-start w-full">
                    <img class="w-5 h-5" :src="info" alt="">
                    <p class="font-semibold text-xs">Las sesiones duran 10 horas desde su creación</p>
                </div>
            </div>
            <button
                v-if="isFromLogin"
                class="bg-tp-yellow p-3 px-8 rounded-xl mx-auto" 
                @click="userLogout">
                <p class="text-tp-black font-bold">Salir</p>
            </button>
        </div>
        <DJCustomizationModal
            v-if="showCustomizationModal" 
            @onCloseModal="showCustomizationModal = false"
            @chooseTop="navigateToRanking('top')"
            @chooseManual="navigateToRanking('manual')"
        />
    </div>
</template>

<script>
import NavBar from '@/components/Common/NavBar.vue';
import DJHomeOption from '@/components/DJ/DJHomeOption.vue';
import DJActiveSessionOption from '@/components/DJ/DJActiveSessionOption.vue';
import info from '@/assets/images/info-icon.png';
import createLoungeService from '@/service/loungeService';
import { getSessionsInfo, getDJPrivateInfo, logout, createIndependentSession, createAutoRanking } from '@/service/djService';
import { useRouter, useRoute} from 'vue-router';
import { ref, onMounted } from 'vue';
import DJCustomizationModal from '../../components/DJ/DJCustomizationModal.vue';
export default {
    name: 'DJHome',
    components: {
        NavBar,
        DJHomeOption,
        DJActiveSessionOption,
        DJCustomizationModal
    },
    setup() {
        const isLoading = ref(true);
        const cantCreateSession = ref(null);
        const existsActiveSession = ref(false);
        const sessionsInfo = ref(null);
        const profileData = ref(null);
        const balance = ref(0);
        const { fetchLoungeDetails } = createLoungeService();

        const router = useRouter();
        const route = useRoute();

        const showCustomizationModal = ref(false);
        const newSessionId = ref(null);


        const isFromLogin= route.params.fromwhere==="login";

        const userLogout = () => {
            logout();
            router.push('/dj/login');
        };


        const navigateToRanking = async (choice) => {
            showCustomizationModal.value = false;

            if (choice === 'top') {
                try {
                    const response = await createIndependentSession(36000);
                    if (response.result === true) {
                        newSessionId.value = response.message;
                        const rankingCreated = await createAutoRanking(newSessionId.value);
                        console.log("Ranking automático creado:", rankingCreated);
                        router.push(`/dj/active-session/${newSessionId.value}/ranking`);
                    }
                    
                } catch (error) {
                    console.error("Error al crear ranking automático:", error);
                }
            } else if (choice === 'manual') {
                const response = await createIndependentSession(36000);
                if (response.result === true) {
                    router.push('/dj/create-ranking');
                }    
            }
        };


        const handleRedirect = async (redirection) => {
            if(redirection == 'my-profile'){
                router.push('/dj/private-area');
            } else if(redirection == 'create-session'){
                if (existsActiveSession.value){
                    const loungeId = sessionsInfo.value.current_session.loungeData.hexID
                    const details = await fetchLoungeDetails(loungeId);
                    console.log(details)
                    if (details.public.ranking === null && details.public.status === 'LOADING'){
                        router.push(`/dj/create-ranking`);
                    } else{
                        router.push(`/dj/active-session/${loungeId}/ranking`);
                    }
                    
                } else {               
                    showCustomizationModal.value = true;     
                }
            }
        };

        onMounted(async () => {
            isLoading.value = true; // Iniciar la carga
            try {
                sessionsInfo.value = await getSessionsInfo();
                if (sessionsInfo.value.current_session !== null) {
                    existsActiveSession.value = true;
                }
                if (isFromLogin){
                    profileData.value = await getDJPrivateInfo();
                    balance.value = profileData.value.saldo.toFixed(2);
                    const role = Number(profileData.value.role);
                    cantCreateSession.value = !(role === 0 || role === 1);
                }
                
            
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                isLoading.value = false;
            }
        });
    
        return{
            userLogout,
            handleRedirect,
            cantCreateSession,
            balance,
            sessionsInfo,
            existsActiveSession,
            isLoading,
            isFromLogin,
            info,
            navigateToRanking,
            showCustomizationModal,
            newSessionId
        }
    }
  }
</script>