<template>
  <div class="relative mx-8">
    <Transition name="modal-fade">
      <div v-if="!isNavBarVisible" class="flex justify-between items-center gap-2" style="height: 48px;">
          <DJActionButton v-if="!isCreateRanking" :image="arrowBack" @click="handleBack" class="z-20"/>
          <p class="absolute left-1/2 -translate-x-1/2 transform font-bold text-lg w-full text-center">Añade <span v-if="isCreateRanking || isCreateGeneralVoting">una</span><span v-if="!isCreateRanking && !isCreateGeneralVoting">tu</span> canción</p>
      </div>
    </Transition>
    <div class="flex items-center">
      <div v-if="true" class="relative add-song-nav-bar mt-2" :class="{'opacity-100 bg-tp-black border-2 border-tp-yellow': true}">
        <img class="w-4 h-4 cursor-pointer" :src="lens" alt="" @click="openSearchBar">
        <input @click="openSearchBar" v-model="inputValue" @input="handleInputChange" type="text" placeholder="Busca tu canción..." class="w-full h-full font-semibold text-tp-yellow py-2 bg-tp-black focus:outline-none">
        <img v-if="isNavBarVisible" class="w-5 h-5 cursor-pointer" :class="[{'opacity-0': !isNavBarVisible}, {'opacity-100 transition-opacity duration-200': isNavBarVisible}]" :src="close" alt="" @click="toggleSearchBar">
      </div>
    
        <!-- <p v-if="isNavBarVisible" @click="toggleSearchBar" class="text-tp-yellow">Cancelar</p> -->
    </div>
  </div>
</template>

<script>
import lens from '@/assets/images/lens-icon.png'
import darkLens from '@/assets/images/dark-lens-icon.png'
import close from '@/assets/images/close-icon.png';
import logo from '@/assets/images/logo.png'
import DJActionButton from '@/components/DJ/DJActionButton.vue';
import arrowBack from '@/assets/images/arrow-back.png';
import { useRouter} from 'vue-router'
import { ref, watch, onMounted } from 'vue'

export default {
    name: 'AddSongNavBar',
    components: {
        DJActionButton
    },
    props: {
      isCreateRanking: Boolean,
      isCreateGeneralVoting: Boolean,
      emptyInput: Boolean,
      selectedSongs: Array,
      loungeId: Number,
      searchSong: String
    },
    setup(props, { emit }) {
        const router = useRouter();

        const inputValue = ref(props.searchSong || '');

        const isNavBarVisible = ref(false);

        const handleInputChange = (event) => {
          const value = event.target.value
          emit('search', value)
        }

        const handleInputProp = (value) => {
          emit('search', value)
        }

        const openSearchBar = () => {
          if (isNavBarVisible.value === true) return
          isNavBarVisible.value = true
        }

        const toggleSearchBar = () => {
          isNavBarVisible.value = !isNavBarVisible.value
          if (isNavBarVisible.value === false) {
            inputValue.value = '';
            emit('close')
          }
        }
        const handleBack = () => {         
          console.log(window.history.length) 
          if (!props.isCreateRanking && !props.isCreateGeneralVoting)  {
            router.push(`/lounge/${props.loungeId}`);
          } else{
            console.log('loungeId: ', props.loungeId)
            router.push(`/dj/active-session/${props.loungeId}`)
          }
        }

        onMounted(() => {
          if (props.searchSong) {
            handleInputProp(props.searchSong);
          }
        });
          

        watch(
          () => props.emptyInput,
          (newVal) => {
            if (newVal) {
              if (inputValue.value) {
                inputValue.value = '';
              }
              resetEmptyInput();
            }
          }
        );

        watch(
          () => props.selectedSongs,
          (newVal) => {
            if (props.isCreateRanking && newVal && newVal.length === 3) {
              toggleSearchBar();
            } else if (props.isCreateGeneralVoting && newVal && newVal.length === 4) {
              toggleSearchBar();
            }
          },
          { deep: true }
        );

        const resetEmptyInput = () => {
          emit('resetEmptyInput');
        };

        return {
            lens,
            darkLens,
            close,
            logo,
            isNavBarVisible,
            toggleSearchBar,
            handleInputChange,
            handleBack,
            inputValue,
            arrowBack,
            openSearchBar
        }
    }
}
</script>

<style>
.add-song-nav-bar {
    position: absolute;
    display: flex;
    align-items: center;
    right: 0;
    top: 0;
    opacity: 0;
    padding: 8px;
    gap: 10px;
    width: 100%;
    height: 48px;
    border-radius: 16px;
    animation: slideNavBar 0.2s linear forwards;
}

@keyframes slideNavBar {
  from {
    width: 20%;
  }
  to {
    width: 100%;
  }
}

input::placeholder {
  color: #F9F871 !important;
  opacity: 0.5 !important;
}

input::-ms-input-placeholder {
  color: #F9F871 !important;
  opacity: 0.75 !important;
}
</style>