<template>
  <div id="confirm-payment" class="h-screen">
    <div class="flex flex-col justify-center items-center min-h-screen pb-12 px-8 space-y-8">
      <img :src="tickIconCircle" alt="tickIconCircle" class="text-tp-black w-40 h-40">
      <p class="text-center mx-4 font-bold text-3xl" v-html="text1"></p>
      <p class="text-center mx-2" v-html="text2"></p>
      <div class="buttons flex flex-col items-center space-y-6">
        <button @click="handleRedirection" class="w-80 py-3 px-4 bg-tp-yellow placeholder-tp-placeholder-black text-tp-black font-semibold rounded-2xl">
          Continúa con TuParty
        </button>
      </div>
    </div>
    <LotteryModal v-if="isLotteryActive" :premio="premio" @onCloseLotteryModal="closeLotteryModal" />
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { loadStripe } from '@stripe/stripe-js';
import { useRoute, useRouter } from 'vue-router';
import LotteryModal from '@/components/Common/LotteryModal.vue';
import createLoungeService from '@/service/loungeService';

export default {
  name: 'ConfirmPayment',
  components: {
    LotteryModal
  },
  setup() {
    const route = useRoute();
    const router = useRouter();

    const loungeId = route.params.id;

    const { fetchLoungeDetails } = createLoungeService();

    const loungeDetails = ref(null);

    const isLotteryActive = ref(false);
    const premio = ref(null);

    const tickIconCircle = require('@/assets/images/tick-icon-circle.png');
    const text1 = 'Pago confirmado'
    const text2 = '¡Gracias por adquirir tus votos!<br><br>Recuerda que SOLO se te cobrará en caso de que tu canción sea la elegida'

    let stripe = null

    onMounted(async () => {
      loungeDetails.value = await fetchLoungeDetails(loungeId);
      isLotteryActive.value = loungeDetails.value.public.tiene_premios;
      premio.value = loungeDetails.value.public.premio;
      checkStatus();
    });


    const initialize = async () => {
      stripe = await loadStripe("pk_test_51Ope8FHdxAPIqguouk0aGbUpvBHbY7r0vuKmj3s3owFeip3lzUYprfbO2L4GRETz1lAexLUebIIlSAKTMZDspxmP004F5faPYU");
    }

    // TODO: REFACTOR
    const checkStatus = async () => {
      await initialize();
      const clientSecret = new URLSearchParams(window.location.search).get(
        "payment_intent_client_secret"
      );

      if (!clientSecret) {
        return;
      }

      const { paymentIntent } = await stripe.retrievePaymentIntent(clientSecret);

      switch (paymentIntent.status) {
        case "succeeded":
          this.showMessage("Payment succeeded!");
          break;
        case "processing":
          this.showMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          this.showMessage("Your payment was not successful, please try again.");
          break;
        default:
          this.showMessage("Something went wrong.");
          break;
      }
    };

    const handleRedirection = () => {
      router.push(`/lounge/${loungeId}`)
    };

    const closeLotteryModal = () => {
      isLotteryActive.value = false;
    };

    return {
      stripe,
      tickIconCircle,
      text1,
      text2,
      handleRedirection,
      closeLotteryModal,
      isLotteryActive,
      premio
    };
  }
};
</script>
