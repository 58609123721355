<template>
  <!-- <TermsCookiesModal 
    v-if="showModal"
    :title="'Aceptación de cookies'"
    :message="cookiesMessage"
    :acceptButtonText="'Aceptar'"
    :declineButtonText="'Rechazar'"
    @onCloseModal="acceptTerms(thirdPartyCookies)"
  /> -->
  <div class="min-h-screen" :class="[{'bg-tp-black': $route.path !== '/ranking'},{'bg-gradient-to-br from-tp-black to-tp-dark-green': $route.path === '/ranking'}]">
    <!-- <div v-if="!showModal" class="absolute z-10 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 -mt-20"> -->
    <div class="absolute z-10 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 -mt-20">
      <Spinner v-if="spinnerVisible.value"/>
    </div>
    <router-view/>
  </div>
</template>

<script>
import { ref, onMounted, watch } from 'vue';
import { useRoute } from 'vue-router';
// import { useStore } from 'vuex';
import Spinner from '@/components/Common/Spinner.vue'
// import { computed } from 'vue';
// import TermsCookiesModal from '@/components/Common/TermsCookiesModal.vue';
// import { sendAcceptTerms } from '@/service/termsService';

export default {
  components: {
    Spinner,
  },
  setup() {
    // const cookiesMessage = 'Este sitio web utiliza cookies propias y de otras entidades, para poder acceder y usar su información para finalidades necesarias de TuParty. Si no está de acuerdo con alguna de estas finalidades, no podrá navegar por la web.';
    const spinnerVisible = ref(false);
    // const store = useStore();

    // const showModal = computed(() => store.state.showTermsModal);

    const loungeId = ref(null);
    const route = useRoute();
    
    watch(
      () => route.params.id,
      (newId) => {
        if (newId) {
          loungeId.value = newId;
          console.log('Lounge ID actualizado:', newId);
        }
      }
    );

    // const acceptTerms = async (thirdPartyCookies) => {
    //   // await sendAcceptTerms(thirdPartyCookies);
    //   store.dispatch('acceptTerms', thirdPartyCookies);
    // };

    onMounted(() => {

      console.log('Lounge ID:', loungeId);

      const preventExtraScroll = () => {
        if (window.scrollY + window.innerHeight > document.documentElement.scrollHeight) {
          
          window.scrollTo({
            top: document.documentElement.scrollHeight - window.innerHeight,
            behavior: 'smooth' 
          });
        }
      };

      window.addEventListener('scroll', preventExtraScroll);


      document.addEventListener('touchmove', function(event) {
        if (event.scale && event.scale !== 1) {
          event.preventDefault();
        }
      }, { passive: false });

      document.addEventListener('gesturestart', function(event) {
        event.preventDefault();
      });

      document.addEventListener('gesturechange', function(event) {
        event.preventDefault();
      });

      document.addEventListener('gestureend', function(event) {
        event.preventDefault();
      });

      let lastTouchEnd = 0;
      document.addEventListener('touchend', function(event) {
        let now = new Date().getTime();
        if (now - lastTouchEnd <= 300) {
          event.preventDefault();
        }
        lastTouchEnd = now;
      }, false);
    });
    
    return {
      spinnerVisible
    }
  }
}
</script>
