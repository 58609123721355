<template>
  <div class="relative flex flex-col gap-6 py-8">
    <AddSongNavBar class="z-20" @search="handleSearchSong" @close="handleCloseSongNavBar" @resetEmptyInput="resetEmptyInput" :isCreateRanking="isCreateRanking" :isCreateGeneralVoting="isCreateGeneralVoting" :emptyInput="emptyInput" :selectedSongs="selectedSongs" :loungeId="loungeId" :searchSong="searchSong"/>
    <div v-if="!searchTerm && selectedSongs.length < 1" class="z-10">
      <p class="font-extrabold text-2xl px-8">Populares</p>
      <div class="overflow-popular-songs-container">
        <div :style="{'--nSongs': 4}" class="popular-songs-container" v-if="isLoadingSuggestions" >
          <PopularSongSkeleton />
        </div>
        <div :style="{'--nSongs': popularSongs.length}" class="popular-songs-container" v-else>
          <PopularSong 
            class="mt-4" 
            v-for="(popularSong, index) in popularSongs" 
            :key="index" 
            :song="popularSong.songName" 
            :artist="popularSong.artist" 
            :image="popularSong.image" 
            @click="handleSearchSongClick(popularSong)"
          />
        </div>
      </div>
      <div class="px-8 mt-4">
        <p class="font-extrabold text-2xl">Éxitos</p>
        <div class="flex flex-col gap-4 mt-4" v-if="isLoadingSuggestions">
          <SongCardSkeleton />
        </div>
        <div class="flex flex-col gap-4 mt-4" v-else>
          <SongCard v-for="(hitSong, index) in hitSongs" :key="index" :song="hitSong.songName" :artist="hitSong.artist" :image="hitSong.image" @click="handleSearchSongClick(hitSong)"/>
        </div>
      </div>
    </div>
    <div v-else-if="!searchTerm && selectedSongs.length >= 1" class="flex flex-col gap-10 items-center mx-8" :class="{'z-20': !modalVisible}">
      <div class="flex flex-col gap-6 w-full">
        <p class="text-tp-yellow font-extrabold text-2xl">Canciones seleccionadas</p>
        <div class="flex flex-col gap-4">
          <p class="font-bold">({{ selectedSongs.length }}/{{ isCreateRanking ? 3 : isCreateGeneralVoting ? 4 : null }})</p>
          <DJSelectedSong v-for="(song, index) in selectedSongs" :key="index" :index="index + 1" :name="song.songName" :artist="song.artist" :image="song.image" @deleteSong="handleDeleteSong"/>
        </div>
        <p class="text-tp-yellow font-semibold" v-if="createSessionError">Debes seleccionar 3 canciones para crear un ranking, y 4 para crear una 4-picks.</p>
      </div>
      <Button
        class="relative flex justify-center items-center bg-tp-yellow py-3 px-16 w-max rounded-xl text-tp-black font-bold"
        @click="handleCreate" text="Crear" :isLoading="isLoading" />
    </div>
    <div v-else>
      <div class="px-8 mt-4" v-if="searchLoading">
        <div class="flex flex-col gap-4 mt-4">
          <SongCardSkeleton />
        </div>
      </div>
      <div class="px-8 mt-4">
        <div class="flex flex-col gap-4 mt-4">
          <SongCard class="cursor-pointer" v-for="(song, index) in searchSongs" :key="index" :song="song.songName" :artist="song.artist" :image="song.image" @click="handleSearchSongClick(song)" :class="{'z-20': !modalVisible}"/>
        </div>
      </div>
    </div>
    <Transition name="modal-fade">
      <AddVotesModal
        v-if="modalVisible"
        modalVisible="true"
        :votePlans=votePlans
        :loungeDetails="details"
        :isLoading="isLoadingLoungeDetails"
        @submitVotePlan="handlePayment"
        @onCloseModal="closeModal"
        @onClickPromocode="handlePromocode"
        @onClickFreeVote="handleFreeVote"
        :class="[
          {'transition-opacity duration-300 opacity-100 z-20': modalVisible}, 
          {'opacity-0': !modalVisible}
        ]"
      />
    </Transition>
    <GenericInfoModal 
      v-if="cantAddSongModalVisible"
      :title="'No da tiempo'"
      :message="'Con menos de un minuto restante, al DJ no le daría tiempo a preparar tu canción'"
      :buttonText="'Entendido'"
      @onCloseModal="handleCloseCantAddSongModal" 
    />
    <GenericInfoModal 
      v-if="promocodeFailedModal"
      :title="titlePromocodeFail"
      :message="messagePromocodeFail"
      :buttonText="'Entendido'"
      @onCloseModal="handleClosePromocodeFailedModal" 
    />
    <ConfirmFreeVoteModal
        v-if="confirmPromoCodeModal" 
        @onCloseModal="handleCloseConfirmModal" 
        :promocode="true"
        :class="[{'transition-opacity duration-300 opacity-100 z-30': confirmPromoCodeModal}, {'opacity-0': !confirmPromoCodeModal}]"
      />
    <ConfirmFreeVoteModal
      v-if="confirmFreeVote" 
      @onCloseModal="handleCloseConfirmModal" 
      :class="[{'transition-opacity duration-300 opacity-100 z-30': confirmFreeVote}, {'opacity-0': !confirmFreeVote}]"
    />
  </div>
</template>

<script>
import { onMounted, ref, onBeforeUnmount} from 'vue';
import AddSongNavBar from '@/components/AddSong/AddSongNavBar.vue';
import PopularSong from '@/components/AddSong/PopularSong.vue';
import PopularSongSkeleton from '@/components/Common/PopularSongSkeleton.vue';
import SongCardSkeleton from '@/components/Common/SongCardSkeleton.vue';
import Button from '@/components/Common/Button.vue';
import SongCard from '@/components/AddSong/SongCard.vue';
import  AddVotesModal from '@/components/Common/AddVotesModal.vue';
import  GenericInfoModal from '../components/Common/GenericInfoModal.vue';
import  DJSelectedSong from '@/components/DJ/DJSelectedSong.vue';

import { useRoute, useRouter } from 'vue-router';
import { searchItunesTrack, getSuggestions } from '../service/musicService';
import { useVotePlans } from '@/composables/votePlans.js';
import { precreateVote } from '@/service/djService';
import { createRanking, getSessionsInfo } from '../service/djService';
import createLoungeService from '@/service/loungeService';
import votingService from '@/service/votingService';
import ConfirmFreeVoteModal from '../components/Ranking/ConfirmFreeVoteModal.vue';

export default {
  name: 'AddSong',
  components: {
    AddSongNavBar,
    AddVotesModal,
    PopularSong,
    DJSelectedSong,
    SongCard,
    Button,
    GenericInfoModal,
    PopularSongSkeleton,
    SongCardSkeleton,
    ConfirmFreeVoteModal
  },
  setup () {
    const TIME_SEARCH_WAIT = 500;
    const searchTerm = ref(null);
    const searchLoading = ref(false);
    const searchSongs = ref([]);
    const timerSearch = ref(null);
    const popularSongs = ref([]);
    const route = useRoute();
    const router = useRouter();
    const loungeId = route.params.id;
    const searchSong = route.params.searchSong;
    const songSelected = ref(null);
    const isCreateRanking = ref(false);
    const isCreateGeneralVoting = ref(false);
    const hitSongs = ref([]);
    const selectedSongs = ref([]);
    const emptyInput = ref(false);
    const isLoading = ref(false);
    const sessionsInfo= ref(null);
    const details = ref(null);
    const songId = ref(null);
    const cantAddSongModalVisible = ref (false);
    const { fetchLoungeDetails } = createLoungeService();

    // loading states
    const isLoadingSuggestions = ref(false);
    const isLoadingLoungeDetails = ref(false);
    const isLoadingSessionsInfo = ref(false);
    const isLoadingCreateRanking = ref(false);
    const isLoadingPrecreateVote = ref(false);

    const promocodeFailedModal = ref(false);
    const messagePromocodeFail = ref(null);
    const titlePromocodeFail = ref(null);
    const confirmPromoCodeModal = ref (false);
    const confirmFreeVote = ref (false);

    const loungeVotingService = votingService(loungeId);

    const pollingInterval = ref(null);

    if (route.name === 'DJCreateRanking') {
      isCreateRanking.value = true;
    } 

    if (route.name === 'DJCreateGeneralVoting') {
      isCreateGeneralVoting.value = true;
    }

    const handleSearchSongClick = (song) => {
      if (isCreateRanking.value || isCreateGeneralVoting.value) {
        handleAddSong(song);
      } else {
        songId.value = song.songId === undefined ? song.trackId : song.songId
        openModal(songId.value);
      }
    }

    const handleFreeVote = () => {
      try {
        modalVisible.value=false;
        loungeVotingService.rankingFreeBoost(songId.value);
        confirmFreeVote.value=true;
      } catch (error) {
        console.log(error);
      }
    };

    const handlePromocode = async (promocode) => {
      try {
        const response = await loungeVotingService.applyPromocode(songId.value, promocode);
        if (response.success === true){
          modalVisible.value=false;
          confirmPromoCodeModal.value = true;
        } else if (response.error === "NO_EXIST"){
          titlePromocodeFail.value="No existe"
          messagePromocodeFail.value="El código promocional que has introducido no existe.  Revisa si has puesto alguna letra/número mal."
          promocodeFailedModal.value=true;
          console.log('NO_EXIST')

        } else if (response.error === "USED_USER"){
          titlePromocodeFail.value="Ya utilizado"
          messagePromocodeFail.value="El código promocional que has introducido ya lo has canjeado antes.  No puedes usarlo más de una vez :(."
          promocodeFailedModal.value=true;
          console.log('USED_USER')

        } else if (response.error === "USED"){
          titlePromocodeFail.value="Ya utilizado"
          messagePromocodeFail.value="El código promocional que has introducido ya ha sido canjeado por alguien antes que tú, lo sentimos :(."
          promocodeFailedModal.value=true;
          console.log('USED')

        } else{
          titlePromocodeFail.value="Error"
          messagePromocodeFail.value="No sabemos qué ha podido pasar, pero algo no ha ido bien, prueba otra vez dentro de un rato."
          promocodeFailedModal.value=true;
          console.log('Error')

        }
        
      } catch (error) {
        console.log('Error al aplicar el código promocional:', error);
        titlePromocodeFail.value="Error"
        messagePromocodeFail.value="No sabemos qué ha podido pasar, pero algo no ha ido bien, prueba otra vez dentro de un rato."
        promocodeFailedModal.value=true;
      }      
    };

    const handleClosePromocodeFailedModal = () => {
      promocodeFailedModal.value = false;
    };

    const handleCloseConfirmModal = () => {
      confirmPromoCodeModal.value = false;
      confirmFreeVote.value=false;
      router.push(`/lounge/${loungeId}`);
    };

    const { votePlans } = useVotePlans();

    const handleSearchSong = async (value) => {
      if (searchTerm.value == '' || searchTerm.value == null) {
        searchLoading.value = true;
      }

      // reset timer
      if (timerSearch.value) {
        clearTimeout(timerSearch.value);
        timerSearch.value = null;
      }

      // set search term value (input from user)
      searchTerm.value = value;

      // control value of searchbar
      if (value == '' || value == null) {
        console.log(value);
        searchSongs.value = [];
        return;
      }

      timerSearch.value = setTimeout(() => {
        searchItunesTrack(value).then(response => {
          searchSongs.value = response;
        }).catch(err => {
          console.log(err);
          searchTerm.value = null;
        }).finally(() => {
          searchLoading.value = false;
        });
      }, TIME_SEARCH_WAIT);
    };

    const handleCloseSongNavBar = async () => {
      // clear timer
      if (timerSearch.value) {
        clearTimeout(timerSearch.value);
        timerSearch.value = null;
      }

      // reset searcher values
      searchTerm.value = null;
      searchSongs.value = [];
    };

    const modalVisible = ref(false);

    const openModal = (song) => {
      songSelected.value = song;
      modalVisible.value = true;
    };

    const closeModal = () => {
      if (isLoadingLoungeDetails.value === false) {
        modalVisible.value = false;
      }
    };

    const handlePayment = async (votePlanPrice) => {
      router.push(`/lounge/${loungeId}/song/${songSelected.value}/pay/${votePlanPrice}/ranking`); 
    };

    const handleCloseCantAddSongModal = () => {
      cantAddSongModalVisible.value = false;
      router.push(`/lounge/${loungeId}`)
    };

    const handleAddSong = (song) => {
      if (isCreateGeneralVoting.value && selectedSongs.value.length < 4 && !selectedSongs.value.find(s => s.songName === song.songName && s.artist === song.artist)) {
        selectedSongs.value.push(song)
        searchTerm.value = null
        searchSongs.value = []
        emptyInput.value = true;
      } else if (isCreateRanking.value && selectedSongs.value.length < 3 && !selectedSongs.value.find(s => s.songName === song.songName && s.artist === song.artist)) {
        selectedSongs.value.push(song)
        searchTerm.value = null
        searchSongs.value = []
        emptyInput.value = true;
      }
      createSessionError.value = false;
    };

    const resetEmptyInput = () => {
      emptyInput.value = false;
    }

    const handleDeleteSong = (index) => {
      selectedSongs.value.splice(index, 1)
      createSessionError.value = false;
    }

    const createSessionError = ref(false)

    const handleCreate = async () => {
      isLoading.value = true;
      if (isCreateRanking.value && selectedSongs.value.length === 3) {
        try{
          isLoadingSessionsInfo.value = true;
          sessionsInfo.value = await getSessionsInfo();
          isLoadingSessionsInfo.value = false;
          if (sessionsInfo.value.current_session !== null){
              if (sessionsInfo.value.current_session.loungeData.hexID){
                  const loungeId = sessionsInfo.value.current_session.loungeData.hexID
                  isLoadingCreateRanking.value = true;
                  await createRanking(selectedSongs.value, loungeId)
                  isLoadingCreateRanking.value = false;
                  router.push(`/dj/active-session/${loungeId}/ranking`);
              }
              else {
                router.push('/dj/login');
              }     
          }
          else {
            router.push('/dj/login');
          }

        }
        catch{
          router.push('/dj/login');
        } 
        
          
      } else if (isCreateGeneralVoting.value && selectedSongs.value.length === 4) {
        // precreate vote
        isLoading.value = true;
        console.log(selectedSongs.value)
        let songsToCreateVote = []
        // TODO: MUSIC DELETE
        selectedSongs.value.forEach(song => {
          var id = song.songId === undefined ? song.trackId : song.songId
          songsToCreateVote.push(id)
        })
        isLoadingPrecreateVote.value = true;
        const response = await precreateVote(selectedSongs.value, loungeId);
        isLoadingPrecreateVote.value = false;
        isLoading.value = false;
        if(response && response.result) {
          //TODO: Make redirection to specific page. NOT -1 @samuelcobos
          router.go(-1)
        }
      } else {
        createSessionError.value = true
      }
      isLoading.value = false;
      
    }

    onMounted(async () => {
      if (!isCreateGeneralVoting.value && !isCreateRanking.value){
        details.value = await fetchLoungeDetails(loungeId);
      } 
      try {
        isLoadingSuggestions.value = true;
        const songs = await getSuggestions();
        isLoadingSuggestions.value = false;
        const len = songs.length;
        
        hitSongs.value = songs.slice(0, Math.ceil(len / 2));
        popularSongs.value = songs.slice(Math.ceil(len / 2), len);

      } catch (error) {
        console.error('Error fetching popular songs:', error);
      }
      pollingInterval.value = setInterval(fetchDetails, 3000);
    });

    onBeforeUnmount(() => {
      clearInterval(pollingInterval.value);
      pollingInterval.value = null;
    });


    const fetchDetails = async () => {
      if (!loungeId) return;
      const details = await fetchLoungeDetails(loungeId);
      if (details && details.public) {
        details.value = details;
      }
    };

    return {
      searchSongs,
      searchLoading,
      searchTerm,
      handleSearchSong,
      handleCloseSongNavBar,
      handlePayment,
      timerSearch,
      popularSongs,
      hitSongs,
      openModal,
      closeModal,
      modalVisible,
      votePlans,
      handleSearchSongClick,
      resetEmptyInput,
      emptyInput,
      handleDeleteSong,
      handleCreate,
      createSessionError,
      selectedSongs,
      isCreateRanking,
      isCreateGeneralVoting,
      precreateVote,
      loungeId,
      searchSong,
      isLoading,
      cantAddSongModalVisible,
      handleCloseCantAddSongModal,
      isLoadingSuggestions,
      isLoadingLoungeDetails,
      isLoadingSessionsInfo,
      isLoadingCreateRanking,
      isLoadingPrecreateVote,
      promocodeFailedModal, 
      messagePromocodeFail,
      titlePromocodeFail,
      handleClosePromocodeFailedModal,
      details,
      handlePromocode,
      handleFreeVote,
      confirmPromoCodeModal,
      handleCloseConfirmModal,
      confirmFreeVote
    };
  }
};
</script>

<style>
.popular-songs-container {
  display: flex;
  gap: 16px;
  width: calc(10rem * var(--nSongs) + 16px * (var(--nSongs) + 1));
}

.overflow-popular-songs-container::-webkit-scrollbar {
  display: none;
}

.overflow-popular-songs-container {
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-x: auto;
  white-space: nowrap;
  padding-left: 2rem;
}
</style>
