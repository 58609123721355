<template>
  <NavBar />
  <SimpleSpinner v-if="isInitialLoading"/>
  <div class="relative" v-else>
    <div class="flex flex-col items-start gap-3 px-8 pt-4 pb-10 w-full">
      <p v-if="!isRankingFinished" class="font-semibold">¡Acepta la primera canción con el botón que tienes debajo!</p>
      <p v-if="isRankingFinished" class="font-semibold">¡Debería estar sonando la canción que está redondeada en rojo!</p>
      <p v-if="noRanking" class="font-semibold">Ahora mismo no hay ningún ranking activo</p>
      <div class="flex items-center justify-between w-full">
        <button :disabled="!isCountdownAvailable" class="flex justify-center items-center rounded-full bg-tp-yellow w-10 h-10 border-2 border-tp-yellow overflow-hidden animate-bounce" @click="startCountdown">
          <div class="flex justify-center items-center w-full" v-if="isLaunchLoading">
            <div class="button-spinner"></div>
          </div>
          <img v-else :src="tick" alt="tick-icon" class="w-6 h-6">
        </button>
        <button v-if="enabled4picks" class="flex justify-center items-center text-tp-black text-5xl rounded-full bg-tp-yellow w-10 h-10 border-2 border-tp-yellow overflow-hidden" @click="handleGeneralVotings">4</button>
      </div>
      <DJRankingSongCard v-if="isRankingFinished" 
        :rankingSong="songToPlay"
        :index="1" 
        :isGeneralVoting="true"  
        :isShouldPlay="true"/>
      <div class="flex flex-col gap-3 w-full">
        <DJRankingSongCard v-for="(rankingSong, index) in rankingSongs" 
        :key="index" 
        :rankingSong="rankingSong" 
        :index="index+1" 
        :isShouldPlay="false"
        :hideBalance="hideBalance" 
        @deleteSong="handleOpenRejectSongModal(rankingSong.songId)"/>
      </div>
      <DJShouldPlaySongModal v-if = "rankingFinishedModal" :songToShow="songToPlay" @onCloseModal="handleCloseModal"/>
    </div>
    <Transition name="modal-fade">
      <DJConfirmRejectSongModal v-if="rejectSongModalVisible" :songId="songToReject" @onRejectSong="handleDeleteSong" @onCloseModal="handleCloseRejectModal"/>
    </Transition>
    <div class="flex justify-center">
      <button
        v-if="enabled4picks" 
        class="bg-tp-yellow p-3 rounded-xl mb-4 mx-auto" 
        @click="openCloseSessionModal">
        <p class="text-tp-black font-bold">Cerrar sesión</p>
      </button>
    </div>
    <Transition name="modal-fade">
      <DJCloseSessionModal v-if="closeSessionModal"  @onCloseSession="handleCloseSession" @onCloseModal="handleCloseCloseSessionModal"/>
    </Transition>
  </div>


</template>

<script>
import NavBar from '@/components/Common/NavBar.vue';
import DJRankingSongCard from '@/components/DJ/DJRankingSongCard.vue';
import DJCloseSessionModal from '@/components/DJ/DJCloseSessionModal.vue';
import clock from '@/assets/images/black-clock.png';
import { useRouter, useRoute } from 'vue-router'
import { ref, onMounted, onBeforeUnmount} from 'vue';
import createLoungeService from '@/service/loungeService';
import { rejectSong, closeSession, rankingCountdown } from '../../service/djService';
import DJShouldPlaySongModal from '../../components/DJ/DJShouldPlaySongModal.vue';
import DJConfirmRejectSongModal from '../../components/DJ/DJConfirmRejectSongModal.vue';
import SimpleSpinner from '@/components/Common/SimpleSpinner.vue';
import tick from '@/assets/images/black-tick.png';

export default {
  name: 'DJRanking',
  components: {
    NavBar,
    DJRankingSongCard,
    DJShouldPlaySongModal,
    DJConfirmRejectSongModal,
    SimpleSpinner,
    DJCloseSessionModal,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const loungeId = route.params.id;
    const loungeDetails = ref(null);
    const detailsLoaded = ref (false);
    const rankingSongs = ref(null);
    const rankingFinishedModal = ref(false);
    const isRankingFinished = ref(false);
    const noRanking = ref (false);
    const pollingInterval = ref(null);
    const rejectSongModalVisible = ref(false);
    const songToReject = ref(null);
    const songToPlay = ref(null);
    const isInitialLoading = ref(true);
    const closeSessionModal = ref (false);
    const isGeneralVotingActive = ref(false);
    const enabled4picks = ref (false);
    const hideBalance = ref(true);
    const isCountdownAvailable = ref(false);
    const isLaunchLoading = ref(false);

    const { fetchLoungeDetails } = createLoungeService();

    const fetchDetails = async () => {
        if (!loungeId) return;
        const details = await fetchLoungeDetails(loungeId);
        if (details && details.public) {
          loungeDetails.value = details;
        }
        if (details && details.private){
          enabled4picks.value = details.private["4picks"];
          hideBalance.value = details.private["hideBalance"]
        }
        checkState();
        if (loungeDetails.value && loungeDetails.value.public && loungeDetails.value.public.ranking) {
            const songsData = Object.values(loungeDetails.value.public.ranking.songs); 
            updateRanking(songsData);
            detailsLoaded.value = true;
        }
    };

    const updateRanking = async (songsData) => {
      rankingSongs.value = songsData.map(song => {
          return {
            ...song.songInfo,
            votes: song.votos,
            revenue: song.revenue
          };
        })
    };

    const checkState = async (retryCount = 0) => {
      if (loungeDetails.value.public && loungeDetails.value.public.status){
        if (loungeDetails.value.public.status === "RANKING" || loungeDetails.value.public.status === "FINISHED_GENERAL_VOTE"){
          isRankingFinished.value = false;
          isCountdownAvailable.value = true;
        } else if (loungeDetails.value.public.status === "FINISHED_RANKING"){
          if (isRankingFinished.value !== true){
            const songDetails = loungeDetails.value.public.lastRankingWinner;
            songToPlay.value =  songDetails;
            rankingFinishedModal.value =true;
          }
          isRankingFinished.value=true;
          isCountdownAvailable.value = false;
        } else if (loungeDetails.value.public.status === "GENERAL_VOTE"){
          isGeneralVotingActive.value = true;
          router.push(`/dj/active-session/${loungeId}/active-voting`)
        } else if (retryCount < 2) { // Esperamos hasta 2 intentos antes de redirigir al login
          const details = await fetchLoungeDetails(loungeId);
          if (details && details.public) {
            loungeDetails.value = details;
          }
          setTimeout(() => checkState(retryCount + 1), 1000); // Reintentar después de 1 segundo
        } 
        else{
          noRanking.value=true;
          router.push('/dj/login')
        }
      }

    }

    const openCloseSessionModal = () => {
      closeSessionModal.value = true;
    };

    const handleCloseCloseSessionModal = () => {
      closeSessionModal.value = false;
    };

    const handleCloseSession = () => {
      closeSession(loungeId);
      closeSessionModal.value = false;
      router.push('/dj/login');
    };

    const handleCloseModal = () => {
      rankingFinishedModal.value = false;
    }
    
    const startCountdown = async () => {
      try{
        isLaunchLoading.value = true;
        await rankingCountdown(loungeId);       
        await new Promise(resolve => setTimeout(resolve, 3000));
        isLaunchLoading.value = false;
      }
      catch {
        isLaunchLoading.value = false;
        console.log('error')
      }
    }

    onMounted(async () => {
        await fetchDetails(); 
        pollingInterval.value = setInterval(fetchDetails, 3000);
        isInitialLoading.value = false;
    });

    onBeforeUnmount(() => {
      clearInterval(pollingInterval.value);
      pollingInterval.value = null; 
    });

    const handleDeleteSong = () => {
      rejectSong (songToReject.value, loungeId)
      rejectSongModalVisible.value = false;
    }

    const handleOpenRejectSongModal = (song) => {
      songToReject.value = song;
      rejectSongModalVisible.value = true;
    }

    const handleCloseRejectModal = () => {
      rejectSongModalVisible.value = false;
    }

    const handleGeneralVotings = () => {
      router.push(`/dj/active-session/${loungeId}/general-votings`);
    }

    return {
      tick,
      clock,
      rankingSongs,
      handleDeleteSong,
      detailsLoaded,
      rankingFinishedModal,
      handleCloseModal,
      isRankingFinished,
      noRanking,
      rejectSongModalVisible,
      songToReject,
      handleOpenRejectSongModal,
      handleCloseRejectModal,
      startCountdown,
      songToPlay,
      isInitialLoading,
      openCloseSessionModal,
      handleCloseCloseSessionModal,
      handleCloseSession,
      closeSessionModal,
      handleGeneralVotings,
      enabled4picks,
      hideBalance,
      isGeneralVotingActive,
      isCountdownAvailable,
      isLaunchLoading
    }
  }
}
</script>

<style scoped>
.button-spinner {
  border: 2px solid rgba(0, 0, 0, 0.5);
  border-left-color: #F9F871;
  border-radius: 50%;
  width: 1.5em;
  height: 1.5em;
  animation: spin 1s linear infinite;
}
</style>