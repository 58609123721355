<template>
  <div class="relative flex justify-between gap-2 items-center w-full max-h-72px rounded-xl p-3" :class="{'bg-tp-yellow cursor-pointer': addSong || mySong, 'bg-tp-black border-2 border-tp-yellow': 
  !addSong && !mySong, 'yellow-shadow' : position == 1}" @click="addSong ? handleAddSong() : emitBoost()">
    <div class="flex items-center gap-2">
      <p class="text-xl font-extrabold" :class="{'text-tp-black': addSong || mySong}">{{ addSong ? '?' : position }}</p>
      <img class="w-10 h-10 rounded-full object-cover border-2 border-tp-yellow" :src="image" alt="">
      <div v-if="!addSong" class="flex flex-col font-semibold leading-none" :class="{'text-tp-black': addSong || mySong}">
        <p class="text-lg w-40 xxs:w-44 xs:w-44 truncate">{{ song }}</p>
        <p class="w-28 truncate opacity-80">{{ artist }}</p>
      </div>
      <p v-else class="font-semibold text-xl text-tp-black">Añade tu canción</p>
    </div>
    <div class="flex items-center gap-2">
      <div class="px-4 rounded-full" :class="[{'bg-tp-black': addSong || mySong}, {'bg-tp-yellow': !addSong && !mySong}]">
        <p class="font-bold text-center text-sm py-1" :class="{'text-tp-yellow': addSong || mySong, 'text-tp-black': !addSong && !mySong}">{{ addSong ? '?' : votes }}</p>
      </div>
      <BoostButton class="absolute -top-3 -right-3" v-if="!addSong && (mySong || songNotSelectedYet)" :ranking="true" :mySong="mySong" :rankingSong="true"/>
    </div>
    <div v-if="addSong" class="absolute -top-2 -right-2 bg-tp-black rounded-full w-6 h-6 text-tp-yellow font-bold flex justify-center items-center border-2 border-tp-yellow">+</div>
  </div>
</template>

<script>
import BoostButton from '@/components/Common/BoostButton.vue'

export default {
  name: 'RankingSong',
  components: {
    BoostButton
  },
  props: {
    position: Number,
    image: String,
    song: String,
    artist: String,
    addSong: Boolean,
    votes: Number,
    mySong: Boolean,
    songNotSelectedYet: Boolean
  },
  setup(props, { emit }) {

    const emitBoost = () => {
      console.log('emitBoost')
      emit('onBoostClick');
    };

    const handleAddSong = () => {
      emit('addSong');
    };

    return {
      emitBoost,
      handleAddSong
    };
  }
};
</script>

<style scoped>
@keyframes pulse-shadow {
  0% {
    box-shadow: 0 0 5px rgba(249, 249, 113, 0.5);
  }
  50% {
    box-shadow: 0 0 20px rgba(249, 249, 113, 1);
  }
  100% {
    box-shadow: 0 0 5px rgba(249, 249, 113, 0.5);
  }
}

.yellow-shadow {
  animation: pulse-shadow 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}
</style>