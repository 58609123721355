<template>
  <div class="modal-overlay z-50" @click.self="handleCloseModal">
    <div class="relative w-full px-6">
      <div class="absolute top-7 right-14 z-10 p-1 bg-tp-yellow rounded-full flex items-center justify-center cursor-pointer" @click="handleCloseModal">
        <img class="w-3 h-3" :src="close" alt="close-icon">
      </div>
      <div class="flex flex-col gap-6 justify-center border-4 border-tp-yellow bg-tp-black rounded-3xl pt-4 pb-6">
        <p class="text-tp-yellow font-bold text-3xl px-6 border-b border-tp-yellow pb-3">Gana {{ premio }}</p>
        <div class="flex flex-col gap-2 text-tp-yellow font-medium px-6 text-lg">
          <p>Si tu canción sale ganadora, podrás ganar {{ premio }} <span class="font-bold">GRATIS</span>.</p>
          <p>Introduce tu <span class="font-bold">correo</span> para poder notificarte si eres ganador.</p>
        </div>
        <div class="flex flex-col gap-4 px-6">
          <div class="flex gap-2 p-2 bg-tp-black border-2 border-tp-yellow rounded-xl text-sm">
              <input 
                  type="text" 
                  class=" bg-tp-black text-tp-yellow border-none outline-none px-2 py-1 w-full font-medium" 
                  placeholder="Correo"
                  v-model="mail"
                       
              />
              <Button 
                  class="bg-tp-yellow text-tp-black px-4 py-2 rounded-md font-bold"
                  @click="handleMail(mail)"
                  :disabled="!isMailValid"
              >
                  Enviar
              </Button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import close from '@/assets/images/black-close-icon.png';
import votingService from '@/service/votingService';
import { ref, watch } from 'vue';
import { useRoute } from 'vue-router';


export default {
  name: 'SearchSongModal',
  components: {
  },
  props: {
    premio: {
      type: String
    },
  },
  setup(props, { emit }) {
    const route = useRoute();
    const loungeId = route.params.id;

    const isMailValid = ref(false);
    const mail = ref(null);

    const handleCloseModal = () => {
      emit('onCloseLotteryModal');
    }

    const handleMail = async (mail) => {
      const loungeVotingService = votingService(loungeId)
      const response = await loungeVotingService.setLotteryMail(mail);
      console.log('setLotteryMail response: ', response);
      handleCloseModal();
    }

    watch(mail, (newVal) => {
      isMailValid.value = newVal.trim().length > 0;
    });

    return {
      close,
      handleCloseModal,
      handleMail,
      mail,
      isMailValid
    }
  }
};
</script>
