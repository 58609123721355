import createLoungeService from './loungeService';
import createApiService from "./apiService";

import { trackInfo } from '../service/musicService';

function votingService(loungeID) {
  const loungeService = createLoungeService(loungeID);
  const apiService = createApiService();

  return {
    async vote(song) {
      try {
        return await loungeService.request('post', '/voting/vote', { songID: song });
      } catch (error) {
        console.error("Error while casting vote:", error);
        throw error;
      }
    },

    async rankingFreeBoost(songId) {
      try {  
        const songInfo = await trackInfo(songId);
        const data = {
          songId: songId,
          songName: songInfo.songName,
          artist: songInfo.artist,
          image: songInfo.image
        }
        return await loungeService.request('post', '/ranking/boost/free', data);
      } catch (error) {
        console.error("Error while applying free boost:", error);
        throw error;
      }
    },

    async votingPayment(object) {
      try {
        return await loungeService.request('post', '/voting/boost/payment', object);
      } catch (error) {
        console.error("Error while casting vote:", error);
        throw error;
      }
    },

    async rankingPayment(object) {
      try {
        return await loungeService.request('post', '/ranking/payment', object);
      } catch (error) {
        console.error("Error while casting vote:", error);
        throw error;
      }
    },

    async applyPromocode(songId, promocode) {
      try {
      
        const songInfo = await trackInfo(songId);

        const data = {
          songId: songId,
          songName: songInfo.songName,
          artist: songInfo.artist,
          image: songInfo.image,
          code: promocode
        }
        return await loungeService.request('post', '/ranking/code', data);
      } catch (error) {
        console.error("Error while applying promo code:", error);
        throw error;
      }
    },

    async setLotteryMail(mail) {
      try {

        const url = "/set-email";

        const data = {
          email: mail,
        };

        const method = "post";

        const response = await apiService.request({
          method,
          url,
          data: data,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        return response.data;
      } catch (error) {
        console.error("Error while setting email for lottery:", error);
        throw error;
      }
    }
  };
}
export default votingService;
